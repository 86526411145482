import * as EventTypes from '../containers/EmbeddableWidget/eventTypes';
const IFRAME_URL = process.env.REACT_APP_IFRAME_URL;
const WRAPPER_ID = 'llapp-container';
const IFRAME_ID = 'llapp-iframe';

class LLAppShim {

  constructor() {};

  init = (clientId) => {
    this.clientId = clientId;
    this.initializeIframe();
    this.mountIframe();
  };

  open = (userData) => {
    this.onLeaseSignedCallback = userData.onLeaseSigned;
    this.onEventFiredCallback = userData.onEventFired;
    this.iframe.contentWindow.postMessage({ type: EventTypes.OPEN_WIDGET, value: userData.orderDetails }, '*');
  };

  close = () => {
    this.iframe.contentWindow.postMessage({ type: EventTypes.CLOSE_WIDGET }, '*');
  };

  initializeIframe = () => {
    if (!document.getElementById(IFRAME_ID)) {
      const iframe = document.createElement('iframe');
      iframe.onload = () => {
        this.iframe.contentWindow.postMessage({ type: EventTypes.INIT_WIDGET, value: {
          clientId: this.clientId
        }}, '*');
      }
      iframe.src = IFRAME_URL
      iframe.id = IFRAME_ID
      iframe.crossorigin = "anonymous"
      iframe.allowTransparency = true
      iframe.frameBorder = 0
      iframe.style.backgroundColor = "transparent"
      iframe.style.width = "100%"
      iframe.style.minHeight = "100%"
      this.iframe = iframe
    }
  };

  mountIframe = () => {
    if (!document.getElementById(IFRAME_ID)) {
      window.addEventListener("message", this.receiveMessage, false);
      const wrapper = document.createElement('div')
      wrapper.id = WRAPPER_ID
      wrapper.style = `top: 0; left: 0; width: 100vw; height: 100%; position: absolute; display: none`
      wrapper.appendChild(this.iframe)
      this.wrapper = wrapper
      document.body.appendChild(wrapper)
    }
  };

  showIframe = () => {
    this.wrapper.style.display = 'block';
    document.body.style = `position: fixed;`
  };

  hideIframe = () => {
    this.wrapper.style.display = 'none';
    document.body.style = `position: absolute;`
  };


  receiveMessage = (event) => {
    // this is where we handle when our widget sends us a message
    if(!!event && !!event.data && !!event.data.type) {
      switch(event.data.type) {
        case EventTypes.CANCEL_FLOW:
          this.onCancelFlow(event.data.data);
          break;
        case EventTypes.FINISH_FLOW:
          this.onFinishFlow(event.data.data);
          break;
        case EventTypes.INIT_WIDGET_DONE:
          this.onInitWidgetDone();
          break;
          case EventTypes.LOG_EVENT:
          this.onLogEvent(event.data.data);
          break;
        case EventTypes.OPEN_WIDGET_DONE:
          this.showIframe();
          break;
        default:
      }
    }
  };

  onCancelFlow = () => {
    this.hideIframe();
  };

  onLogEvent = (data) => {
    this.onEventFiredCallback(data);
  };

  onFinishFlow = (data) => {
    this.onLeaseSignedCallback(data);
    setTimeout(() => {
      this.hideIframe();
    }, 3000)
  };

  onInitWidgetDone = () => {
    const PaypairApi = window.Paypair;
    PaypairApi.init = this.init;
    PaypairApi.open = this.open;
    PaypairApi.close = this.close;
    PaypairApi._c = window.Paypair._c;
    this.runPriorCalls();
    window.Paypair = PaypairApi;
  };

  runPriorCalls = () => {
    window.Paypair._c.forEach(([method, args]) => {
      this[method].apply(this, args);
    });
  }
}

export default ((window) => {
  const stubSdk = window.Paypair;
  const initCall = stubSdk._c.find(call => call[0] === 'init');
  const shim = new LLAppShim();
  stubSdk.init = shim.init;

  initCall && shim.init(initCall[1][0]);
})(global)
